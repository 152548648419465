import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import {
  DEFAULT_RESTRICTIONS,
  PremiumRestriction,
  PREMIUM_PLAN_LIMITS,
} from '../../../constants/premium'
import { ASCEND_PLAN } from '../../../panels/commons/constants/ascend'
import Experiments from '@wix/wix-experiments'
import { EXPERIMENTS } from '../../../panels/commons/constants/experiments'
import { PremiumStatus } from '@wix/ambassador-premium-v1-asset/types'

export default class PremiumApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments
  private remoteApi: RemoteApi
  private cachedData: { [key: string]: any } // TODO: [PERFORMANCE]: Move to react query and use it in other places too

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
    this.cachedData = {}
  }

  public async getRestrictions(): Promise<{
    restrictions: PremiumRestriction
    currentPlan: string
  }> {
    if (this.experiments.enabled(EXPERIMENTS.ASCEND_TO_PREMIUM)) {
      const currentPlan = await this.remoteApi.getCurrentPremiumPlan()
      if (this.cachedData.premiumRestrictions) {
        return this.cachedData.premiumRestrictions
      }
      const { restrictions } = await this.remoteApi.getRestrictions()

      const isTopPremium =
        currentPlan === PremiumStatus.PREMIUM &&
        restrictions.forms.limit === PREMIUM_PLAN_LIMITS[PremiumStatus.PREMIUM].formLimit

      const premiumRestrictions = {
        restrictions: { ...restrictions, isTopPremium } || DEFAULT_RESTRICTIONS,
        currentPlan,
      }
      this.cachedData.premiumRestrictions = premiumRestrictions
      return premiumRestrictions
    }
    return this.getPremiumRestrictions()
  }

  public async getPremiumRestrictions(): Promise<{
    restrictions: PremiumRestriction
    currentPlan: string
  }> {
    const fallbackResponse = {
      restrictions: DEFAULT_RESTRICTIONS,
      currentPlan: ASCEND_PLAN.FREE,
    }

    try {
      const isTemplate = await this.coreApi.isTemplate()

      if (isTemplate) {
        return fallbackResponse
      } else {
        if (this.cachedData.restrictions) {
          return this.cachedData.restrictions
        }

        const res = await this.remoteApi.getPremiumRestrictions()

        if (res) {
          this.cachedData.restrictions = res
          return res as {
            restrictions: PremiumRestriction
            currentPlan: string
          }
        }

        return fallbackResponse
      }
    } catch (err) {
      return fallbackResponse
    }
  }

  public async getCurrentAscendPlan(): Promise<{
    ascendPlan: string
    isTopPremium: boolean
  }> {
    if (this.cachedData.currentPlan !== undefined) {
      return this.cachedData.currentPlan
    }

    try {
      const currentPlan = await this.remoteApi.getCurrentAscendPlan()
      this.cachedData.currentPlan = currentPlan
      return currentPlan
    } catch (err) {
      return { ascendPlan: ASCEND_PLAN.FREE, isTopPremium: false }
    }
  }

  public async getCurrentPremiumPlan(): Promise<{ currentPremiumPlan: string }> {
    if (this.cachedData.currentPremiumPlan !== undefined) {
      return this.cachedData.currentPremiumPlan
    }

    try {
      const currentPremiumPlan = await this.remoteApi.getCurrentPremiumPlan()
      this.cachedData.currentPremiumPlan = currentPremiumPlan
      return { currentPremiumPlan }
    } catch (err) {
      return { currentPremiumPlan: 'FREE' }
    }
  }
}
